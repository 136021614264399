defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/Client',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/SimpleCubeLoader'
  ],
  function (m, Utils, JackpotInfo, SimpleCubeLoader) {
    return function () {
      var flowStepsStructure = m.prop(null);
      var loadedGameClient = m.prop(null);

      this.loadGame = function () {
        if (!this.playType()) {
          return null;
        }

        this.property('stepGoToNext', null);

        this.game().clientContext('multiClient');
        if (this.game().status() === 'completed') {
          if (this.currentFlowStep() === 'receipt') {
            this.loadingClient(false);
            return function () {
              return null;
            };
          }
          this.goToFlowStep(-1);
        }

        var deferred = m.deferred();

        if (this.playType() === 'lightning') {
          requireDs('DanskeSpil/Domain/NumberGames/Scripts/Components/MultiClient/LightningClient', function (GameClient) {
            var gameClientComponent = m.component(GameClient(), { controller: this });
            loadedGameClient(gameClientComponent);
            deferred.resolve(gameClientComponent);
            m.redraw(true);
            this.loading(false);
            this.loadingClient(false);
          }.bind(this));
        } else {
          requireDs('DanskeSpil/Domain/' + this.gameType + '/Scripts/Components/GameClients/' + Utils.formatNaming(this.gameType, 'capitalized') + Utils.formatNaming(this.playType(), 'capitalized') + 'Client', function (GameClient) {
            deferred.resolve(GameClient(m, this.settings, this.property));
            m.redraw(true);
            this.loading(false);
            this.loadingClient(false);
          }.bind(this));
        }

        return deferred.promise;
      }.bind(this);

      var loadDependencies = function (dependencies) {
        var dependenciesPaths = [];

        // when using it with a singe dependency
        if (!Array.isArray(dependencies)) {
          dependencies = new Array(dependencies);
        }

        for (var i = 0; i < dependencies.length; i++) {
          // if a dependency has the `result` property and it's not null
          // then we skip it as we know it is already loaded
          if (dependencies[i].result) {
            continue;
          }

          // else add the dependency path to the `dependenciesPaths` array
          dependenciesPaths.push(dependencies[i].path);
        }

        var deferred = m.deferred();
        requireDs(dependenciesPaths, function () {
          for (var j = 0; j < arguments.length; j ++) {
            dependencies[j].result = arguments[j];
          }

          deferred.resolve(dependencies);
        });
        return deferred.promise;
      };

      this.generateFlowStepsComponents = function () {
        if (flowStepsStructure() &&
            this._flowSteps()[this.currentFlowStepIndex()] &&
            this._flowSteps()[this.currentFlowStepIndex()].dependency.result) {
          this.loading(false);
          m.redraw();
        }
        var preloadedStepsDependencies = this._flowSteps().reduce(function (dependencies, step) {
          // we want to preload only dependencies that are not supposed to be lazyLoaded or
          // if they were already loaded (we know that by checking the `result ` property)
          if (!step.dependency.lazyLoaded && !step.dependency.result) {
            dependencies.push(step.dependency);
          }
          return dependencies;
        }, []);

        var flowStepClassName = function (step) {
          var className = 'multi-client__flow-step';
          className += ' multi-client__flow-step-' + step;
          if (this.currentFlowStep() === step) {
            className += ' multi-client__flow-step--active';
          }
          return className;
        }.bind(this);

        // `individualFlowStepStructure` is used to generate the structure for individual steps and
        // lazyLoad components when condition is met
        var individualFlowStepStructure = function (step, stepIndex) {
          var canRenderStepContent = step.options.renderCondition(this.currentFlowStepIndex()) && step.dependency.result;

          // check why not
          if (!canRenderStepContent) {
            // if it is because the step should be lazyLoaded then do that
            if (step.options.renderCondition(this.currentFlowStepIndex()) &&
              !step.dependency.result &&
              step.dependency.lazyLoaded) {
              var lazyLoadedFlowStep = function () {
                var lazyLoadDeferred = m.deferred();
                loadDependencies(step.dependency).then(function () {
                  lazyLoadDeferred.resolve();
                });
                return lazyLoadDeferred.promise;
              };

              lazyLoadedFlowStep().then(function () {
                // after loading the lazyLoaded dependency render and replace the flow step
                flowStepsStructure()[stepIndex] = individualFlowStepStructure(this._flowSteps()[stepIndex]);
                this.loading(false);
                m.redraw();
              }.bind(this));
            }
          }

          // return the structure for individual step
          return m('div',
            {
              class: flowStepClassName(step.name)
            }, canRenderStepContent ? m.component(step.dependency.result, { controller: this }) : null);
        }.bind(this);

        // we need to wait for all the preloaded dependencies to be loaded
        var deferred = m.deferred();
        loadDependencies(preloadedStepsDependencies).then(function () {
          var stepStructure = this._flowSteps().map(function (step, stepIndex) {
            return individualFlowStepStructure(step, stepIndex);
          }.bind(this));
          deferred.resolve(stepStructure);
        }.bind(this));
        return deferred.promise;
      }.bind(this);

      var Client = {
        controller: function () {
          this.loading(true);
          if (this.currentFlowStepIndex() === -1) {
            this.isStepValid(this.game().isGameValid());
          }
          this.flowStepConfig = function ($elem) {
            $elem.style.transform = 'translateX(-' + ((this.currentFlowStepIndex() + 1) * 100) + '%)';
          }.bind(this);

          if (!loadedGameClient()) {
            this.loadingClient = m.prop(true);
            this.loading(true);
          }

          this.gameClient = loadedGameClient() === null ? this.loadGame() : loadedGameClient;

          this.generateFlowStepsComponents().then(function (data) {
            flowStepsStructure(data);
            this.loading(false);
            m.redraw();
          }.bind(this));

          return this;
        }.bind(this),

        view: function (controller) {
          if (controller.currentFlowStepIndex() === -1) {
            controller.isStepValid(controller.game().isGameValid());
          }
          var jackpotText = JackpotInfo.getDynamicFeed(controller.mcDictionary('game/subtitle'));
          var rows = controller.game().getRowAmount();
          var description = null;
          let descriptionSlot = null;
          const isLoading = () => {
            if (controller.isCampaignEngineOn &&
              !controller.checkingForActiveCampaign()) {
              return controller.checkingForActiveCampaign();
            }
            return controller.loadingClient() || controller.loading();
          };
          const showDescription = () => {
            if (controller.campaign?.()) return true;
            if (controller.isOpenDrawPartOfCampaign()) return true;
            if (controller.activeCampaignModel().campaignType === 'EachDraw' &&
                controller.isOpenDrawPartOfCampaign()) {
              return true;
            }
            if (controller.campaignExtraDraw && controller.isThemeOn?.()) return true;

            return false;
          };
          if (showDescription()) {
            const descriptionRowsCount = controller.game().rowsToGenerate() || rows;
            const ticketsCount = controller.game().numberOfDraws() * descriptionRowsCount;
            let descriptionDictionary = 'game/description';
            if (descriptionRowsCount === 0) {
              descriptionDictionary += 'Zero';
            }
            if (descriptionRowsCount === 1) {
              descriptionDictionary += 'Single';
            }
            description = controller.mcDictionary(descriptionDictionary, {
              campaignName: controller.hasActiveCampaign() ? controller.activeCampaignModel?.().name : controller.campaign?.(),
              rows: descriptionRowsCount,
              ticketsCount
            });

            descriptionSlot = m('.multi-client__client-game-description', [
              m('.client-game-description__inner', m.trust(description)),
              controller.activeCampaignModel().campaignType === 'EachDraw' ? m('.client-game-description__question-button', {
                onclick: () => controller.showCampaignInformOverlay(true)
              },
              m('svg.icon', [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#question-icon' })
              ])
              ) : null
            ]);

          }

          if (controller.property('numbersPickerGameDescriptionSlot')) {
            controller.property('numbersPickerGameDescriptionSlot')(descriptionSlot);
          }

          return m('.multi-client__client-wrapper', [
            isLoading() ? m('.multi-client-loader-wrapper', SimpleCubeLoader()) : [
              controller.preGameClientStep ? m(controller.preGameClientStep, { controller: controller }) : null,
              m('.multi-client__client-game', {
                class: controller.currentFlowStepIndex() === -1 ? 'active' : ''
              }, [
                controller.settings.useNewNumbersPicker ? null : m('.multi-client__flow-step-header', [
                  m('.multi-client__flow-step-title', controller.mcDictionary('game/title')),
                  typeof jackpotText === 'string' && jackpotText.length ? m('.multi-client__flow-step-subtitle', m.trust(jackpotText)) : null,
                ]),
                controller.gameClient(),
                (description?.length > 0 && !controller.property('numbersPickerGameDescriptionSlot')) ? descriptionSlot : null
              ]),
              m('.multi-client__flow-wrapper', {
                config: controller.flowStepConfig
              },
              flowStepsStructure())
            ]
          ]);
        }

      };

      return Client;
    };
  });
