defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/ConfirmOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Context:
    var ConfirmOverlay = function (className, dictionary) {

      // Return:
      return {
        class: m.prop(className),

        render: function (dismiss) {
          return m('div', { class: this.class() }, [
            m('div', { class: 'pannel' }, [
              m('div', { class: 'icon-wrapper' }, [
                m('svg', { class: 'icon' }, m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#info-icon' }))
              ]),
              m('h2', { class: 'headline' }, dictionary.header),
              m('p', { class: 'text' }, dictionary.body),
              m('div', { class: 'buttons' }, [
                m('button', {
                  class: 'btn button-confirm',
                  onclick: dismiss,
                }, dictionary.confirm)
              ])
            ])
          ]);
        }
      };
    };

    // Public functions:
    return ConfirmOverlay;

  });
