defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/RowsList',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/RowItem'
  // eslint-disable-next-line no-unused-vars
  ], function (m, RowItem) {
    const RowsList = {
      controller: function ({ controller }) {
        Object.assign(this, controller);
      },
      view: function (controller) {
        return (
          <div data-uitest-id='rows-list' className='flex flex-col gap-8 p-12'>
            {controller.gameInstance().rows().map((row) => {
              return <RowItem
                controller={controller}
                row={row}
                useCompactView={controller.useCompactView}
              />;
            })}
          </div>
        );
      }
    };

    return RowsList;
  });