defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/TabsWrapper/TabsWrapper',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/NumbersPicker',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/InfoContent',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/KeysContent',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/GuaranteeContent',
  ], function (m, Svg, NumbersPicker, InfoContent, KeysContent, GuaranteeContent) {
    const TabsWrapper = {
      controller: function ({ controller }) {
        Object.assign(this, controller);

        this.tabs = [
          {
            id: 'numberPicker',
            label: this.labelDictionary('systemNumbersPickerTabLabel'),
            icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#game-menu-icon',
          },
          {
            id: 'info',
            label: this.labelDictionary('systemInfoTabLabel'),
            icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#info-icon',
          },
          {
            id: 'keys',
            label: this.labelDictionary('systemKeysTabLabel'),
            icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#key-icon',
          },
          {
            id: 'guarantee',
            label: this.labelDictionary('systemGuaranteeTabLabel'),
            icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#stamp-icon',
          }
        ];
        this.activeTab = m.prop('numberPicker');

        switch (this.gameName) {
        case 'vikinglotto':
          this.pickerSpecificClassNames = 'w-[62.2rem] min-h-[56rem]';
          this.bg1 = 'bg-vikinglotto-blue-1';
          this.bg2 = 'bg-vikinglotto-blue-2';
          break;
        case 'eurojackpot':
          this.pickerSpecificClassNames = 'w-[81.2rem] min-h-[56rem]';
          this.bg1 = 'bg-eurojackpot-carbon-1';
          this.bg2 = 'bg-eurojackpot-carbon-2';
          break;
        default:
          this.pickerSpecificClassNames = 'w-[62.2rem] min-h-[41.9rem]';
          this.bg1 = 'bg-lotto-red-1 group-[.christmas-theme]:bg-lotto-christmas-red-radial-gradient';
          this.bg2 = 'bg-lotto-red-2';
          break;
        }
        this.bg1 = this.bg1 + ' group-[.campaign-custom-graphics-on]:bg-[var(--multiclient-campaign-tertiary-color-500)]';

        this.changeTab = (tabId) => {
          this.activeTab(tabId);
        };
      },
      view: function (controller) {
        const content = () => {
          const contentWrapperHelper = (content) => (<div
            className='flex justify-center flex-1 relative will-change-scroll overflow-y-auto'
            config={($element, isInitialized, context) => {
              if (!isInitialized || context.registered) return;
              document.addEventListener('numberGames.accordion:open', ({ detail: { $accordionElement } }) => {
                if (!$accordionElement) return;
                $element.scroll({ // eslint-disable-line no-jquery/no-event-shorthand
                  top: $accordionElement.offsetTop,
                  behavior: 'smooth',
                });

                // Scroll a bit more to trigger a repaint on iOs devices
                const pageScrollPosition = window.scrollY;
                window.scroll({
                  top: pageScrollPosition + 1,
                  behavior: 'smooth',
                });
              });
            }}
          ><div className='absolute indent-0 text-white text-center'>{content}</div></div>);
          switch (controller.activeTab()) {
          case 'info':
            return contentWrapperHelper(<InfoContent controller={controller} />);
          case 'keys':
            return contentWrapperHelper(<KeysContent controller={controller} />);
          case 'guarantee':
            return contentWrapperHelper(<GuaranteeContent controller={controller} />);
          default:
            return <NumbersPicker controller={controller} className='' />;
          }
        };
        return (<div class={`px-16 ${controller.useCompactView ? '' : 'md:px-0'}`}>
          <div data-uitest-id='tabs-wrapper' className={`max-w-full flex flex-col ${controller.pickerSpecificClassNames} rounded-24 overflow-hidden ${controller.bg2}`}>
            <div data-uitest-id='tabs-buttons' className='flex'>{controller.tabs.map((tab) => {
              const isActive = controller.activeTab() === tab.id;
              return (<div
                data-uitest-id={`tab-button-${tab.id}`}
                className={`flex flex-col flex-1 items-center justify-center py-20 gap-8 rounded-t-24 cursor-pointer text-center ${controller.useCompactView ? '' : 'md:flex-row'} ${isActive ? controller.bg1 : ''}`}
                onclick={() => controller.changeTab(tab.id)}
              >
                <Svg
                  classNames='text-white'
                  src={tab.icon}
                  width='24'
                  height='24'
                />
                <div className={`text-white text-10 uppercase ${isActive ? 'font-extrabold' : 'font-bold'}`}>{tab.label}</div>
              </div>);
            })}</div>
            <div className={`flex flex-1 p-16 ${controller.bg1}`}>
              {content()}
            </div>
          </div>
        </div>);
      }
    };
    return TabsWrapper;
  });