defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/DatePicker', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
], (m, Utils) => {
  const DatePicker = ({ completedDrawsDates, gameTypeSelected, property, selectDrawDate, selectedDrawDate }) => {

    const today = document.body.getAttribute('data-time-now');

    // Initialize.
    if (!property('datePickerCalendarMonthOffset')) property('datePickerCalendarMonthOffset', 0);
    if (!property('datePickerIsDatepickerOpened')) property('datePickerIsDatepickerOpened', false);

    if (!property('datePickerInitStartDate') && selectedDrawDate()) {
      property('datePickerInitStartDate', true);
      const monthOffset = Utils.getDifferenceInMonths(selectedDrawDate(), completedDrawsDates()[0]);

      if (monthOffset > 0) {
        property('datePickerCalendarMonthOffset', -monthOffset);
      }
    }

    const currentCalendarMonth = () => {
      const latestDrawDate = completedDrawsDates()[0];
      const calendarMonth = latestDrawDate ? Utils.getDate(latestDrawDate) : Utils.getDate();
      calendarMonth.setDate(1);
      calendarMonth.setMonth(calendarMonth.getMonth() + property('datePickerCalendarMonthOffset'));
      return calendarMonth;
    };

    const days = () => {
      const daysArr = [];
      let selectedDraw;
      let calendarMonth = currentCalendarMonth();

      if (selectedDrawDate()) {
        selectedDraw = Utils.getDate(selectedDrawDate());
      }

      const firstDayOfMonth = Utils.getDate(calendarMonth);
      firstDayOfMonth.setDate(1);
      const numberOfDaysInMonth = Utils.getNumberOfDaysInMonth(calendarMonth.getFullYear(), calendarMonth.getMonth() + 1);
      let firstWeekDayOfMonth = firstDayOfMonth.getDay();
      let lastWeekDayOfMonth = Utils.getDate(calendarMonth);
      lastWeekDayOfMonth.setDate(numberOfDaysInMonth);
      lastWeekDayOfMonth = lastWeekDayOfMonth.getDay();

      const prevDaysOffset = firstWeekDayOfMonth === 0 ? -6 : -firstWeekDayOfMonth + 1;
      const nextDaysOffset = lastWeekDayOfMonth === 0 ? 0 : 7 - lastWeekDayOfMonth;

      for (let i = prevDaysOffset; i < (numberOfDaysInMonth + nextDaysOffset); i++) {
        const dayInMonth = Utils.getDate(firstDayOfMonth);
        dayInMonth.setDate(dayInMonth.getDate() + i);

        if (i === prevDaysOffset || dayInMonth.getDay() === 1) {
          const week = Utils.getWeekNumber(dayInMonth);
          const selectedWeek = selectedDraw ? Utils.getWeekNumber(selectedDraw) : null;
          const state = [];

          if (week === selectedWeek && calendarMonth.getFullYear() === selectedDraw?.getFullYear()) {
            state.push('current-week');
          }

          daysArr.push({
            week,
            state,
          });
        }

        const state = [];

        const tomorrow = Utils.getDate(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        if (calendarMonth.getMonth() === dayInMonth.getMonth() && dayInMonth.getTime() < tomorrow.getTime()) {
          state.push('current-month');
        }

        if (Utils.isSameDay(selectedDraw, dayInMonth)) {
          state.push('selected');
        }

        if (completedDrawsDates().includes(Utils.formatDateYearMonthDay(dayInMonth))) {
          state.push('draw');
        }

        daysArr.push({
          date: dayInMonth,
          state,
        });
      }

      return daysArr;
    };

    const handleSlipToggle = () => {
      property('datePickerIsDatepickerOpened', !property('datePickerIsDatepickerOpened'));
    };

    const firstCompletedDrawMonthYear = Utils.formatDateMonthYear(completedDrawsDates()[completedDrawsDates().length - 1]) || Utils.formatDateMonthYear(Utils.getDate());
    const lastCompletedDrawMonthYear = Utils.formatDateMonthYear(completedDrawsDates()[0]) || Utils.formatDateMonthYear(Utils.getDate());
    const currentCalendarDateMonthYear = Utils.formatDateMonthYear(currentCalendarMonth());

    const getSlipYear = (date) => {
      const d = Utils.getDate(date);
      if (isNaN(d)) return;
      let year = d.getFullYear();
      return Utils.isWeekEndOfYearAndMonthIsJanuary(d) ? --year : year;
    };

    return m('.winning-numbers-date-picker', {
      class: `winning-numbers-date-picker--${gameTypeSelected()} ${property('datePickerIsDatepickerOpened') ? 'winning-numbers-date-picker--open' : ''}`
    }, [

      m('.winning-numbers-date-picker__slip', {
        onclick: handleSlipToggle
      }, [
        m('.winning-numbers-date-picker__slip-week', [
          m('.winning-numbers-date-picker__slip-week-label', 'Uge'),
          m('.winning-numbers-date-picker__slip-week-week', Utils.getWeekNumber(selectedDrawDate() || today)),
          m('.winning-numbers-date-picker__slip-week-year', getSlipYear(selectedDrawDate() || today)),
        ]),
        m('.winning-numbers-date-picker__slip-date', [
          m('.winning-numbers-date-picker__slip-date-day', Utils.formatDateWeekDay(selectedDrawDate() || today)),
          m('.winning-numbers-date-picker__slip-date-date', Utils.formatDateDayMonth(selectedDrawDate() || today)),
        ]),
        m('.winning-numbers-date-picker__slip-toggle')
      ]),

      m('.winning-numbers-date-picker__calendar', [

        m('.winning-numbers-date-picker__toggle', [
          m('.winning-numbers-date-picker__toggle-prev', {
            class: firstCompletedDrawMonthYear === currentCalendarDateMonthYear
              ? 'winning-numbers-date-picker__toggle-next--disabled' : '',
            onclick: () => {
              property('datePickerCalendarMonthOffset', (property('datePickerCalendarMonthOffset') - 1));
              m.redraw();
            }
          }),
          m('.winning-numbers-date-picker__toggle-month', currentCalendarDateMonthYear),
          m('.winning-numbers-date-picker__toggle-next', {
            class: lastCompletedDrawMonthYear === currentCalendarDateMonthYear
              ? 'winning-numbers-date-picker__toggle-next--disabled' : '',
            onclick: () => {
              property('datePickerCalendarMonthOffset', (property('datePickerCalendarMonthOffset') + 1));
              m.redraw();
            }
          }),
        ]),

        m('.winning-numbers-date-picker__headers',
          ['Uge', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'L\u00F8r', 'S\u00F8n']
            .map((headerName) => m('.winning-numbers-date-picker__header', headerName))
        ),

        m('.winning-numbers-date-picker__days',
          days().map((day) => m('.winning-numbers-date-picker__day', {
            class: day.state?.map((state) => `winning-numbers-date-picker__day--${state}`).join(' '),
            onclick: () => {
              selectDrawDate(Utils.formatDateYearMonthDay(day.date));
              m.redraw();
            },
          }, day.week || day.date.getDate())
          )
        ),

      ]),

    ]);
  };

  return DatePicker;

});
