defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/OptionsListItem', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/CheckButton',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/OptionDetailsModal',
// eslint-disable-next-line no-unused-vars
], function (m, Svg, CheckButton, OptionDetailsModal) {
  const OptionsListItem = {
    controller: function ({ controller, systemName }) {
      Object.assign(this, controller);

      this.detailsModal = new OptionDetailsModal({ controller, systemName });

      switch (this.gameName) {
      case 'vikinglotto':
        this.brandColor = 'text-vikinglotto-blue-1';
        break;
      case 'eurojackpot':
        this.brandColor = 'text-eurojackpot-gold-1';
        break;
      default:
        this.brandColor = 'text-lotto-red-1';
        break;
      }
      this.brandColor = this.brandColor + ' group-[.campaign-custom-graphics-on]:text-[var(--multiclient-campaign-primary-color-500)]';
    },
    view: function (controller, { systemName }) {
      const isSelected = controller.gameInstance().systemName() === systemName;
      if (controller.useCompactView) {
        return (<div
          data-uitest-id={`system-option-${systemName}`}
          className='relative pt-10 px-10 pb-8'
          onclick={() => controller.onSelectSystemName(systemName)}
        >
          <div className='text-12 font-bold whitespace-nowrap'>{systemName}</div>
          <div className={`absolute h-2 bg-black inset-x-14 bottom-0 transition-opacity ${isSelected ? 'opacity-100' : 'opacity-0'}`}/>
        </div>);
      }
      return (<CheckButton
        data-uitest-id={`system-option-${systemName}`}
        className={`${controller.brandColor}`}
        isSelected={isSelected}
        label={systemName}
        onClick={() => controller.onSelectSystemName(systemName)}
        trailingElement={
          <div
            data-uitest-id='system-option-details-button'
            className='flex cursor-pointer'
            onclick={(event) => {
              event.stopPropagation();
              controller.detailsModal.show();
            }}
          >
            <Svg
              src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#question-icon'
              height='24'
              width='24'
            />
          </div>
        }
      />);
    }
  };

  return OptionsListItem;
});