defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Accordions/Accordion',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  ], function (m, Svg) {
    const Accordion = {
      controller: function ({ allowOnlyOneOpen = false }) {

        this.isActive = m.prop(false);
        this.toggleActive = (event) => {
          if (allowOnlyOneOpen && !this.isActive()) {
            document.dispatchEvent(new CustomEvent('numberGames.accordion:closeAll'));
          }
          this.isActive(!this.isActive());
          document.dispatchEvent(new CustomEvent('numberGames.accordion:open', {
            detail: {
              $accordionElement: event.target.closest('[data-uitest-id="accordion"]')
            }
          }));
        };

        this.contentWrapperConfig = ($element, isInitialized) => {
          if (!isInitialized) {
            return;
          }
          if (this.isActive()) {
            $element.style.height = $element.scrollHeight + 'px';
          } else {
            $element.style.height = '0px';
          }
        };

        if (allowOnlyOneOpen) {
          document.addEventListener('numberGames.accordion:closeAll', () => {
            this.isActive(false);
          });
        }
      },
      view: function (controller, { heading, content }) {
        return <div
          data-uitest-id='accordion'
          className='rounded-16 border border-solid border-gray-2 overflow-clip will-change-scroll'>
          <div
            data-uitest-id='accordion-header'
            className='sticky top-0 flex bg-white text-black p-16 cursor-pointer'
            onclick={controller.toggleActive}
          >
            <div className='text-12 font-bold leading-20 uppercase'>{heading}</div>
            <div className={`ml-auto ${controller.isActive() ? 'transform rotate-180' : ''}`}>
              <Svg
                width='16'
                height='16'
                src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#arrow-down-icon'
              />
            </div>
          </div>
          <div className={'transition-[height]'} style={'height:0;'} config={controller.contentWrapperConfig} data-uitest-id='accordion-content'>
            {content}
          </div>
        </div>;
      }
    };

    return Accordion;
  });
