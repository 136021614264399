defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/GameClients/EurojackpotLuckyClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/Lucky',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  ],
  function (Params, NumberGamesLucky, EurojackpotGame, EurojackpotInfo) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Component:
      var root = {
        controller: function () {

          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(EurojackpotGame.setupGame({
            gameInstanceId: this.gameInstanceId(),
            playType: 'Lucky',
            verticalType: settings.verticalType,
            manualGameVersionNo: settings.manualGameVersionNo
          }));

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Tracking - Push productDetails event to dataLayer
          this.game().trackingProductDetails();

        },

        view: function (controller) {
          return (<NumberGamesLucky
            rootSettings={settings}
            rootProperty={property}
            gameInstance={controller.game}
            gameModel={EurojackpotGame}
            gameName={'eurojackpot'}
            rowsOptions={settings.luckyRowOptions || EurojackpotInfo.getLuckyRowOptions()}
            defaultRowCount={EurojackpotInfo.getLuckyDefaultRowCount()}
          />);
        }
      };

      return root;
    };

    // Public functions:
    return GameClient;

  });
