defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/GameClients/EurojackpotSystemClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/System',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
  ],
  function (Params, NumberGamesSystem, EurojackpotGame) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Component:
      var root = {
        controller: function () {

          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(EurojackpotGame.setupGame({
            gameInstanceId: this.gameInstanceId(),
            playType: 'System',
            verticalType: settings.verticalType,
            manualGameVersionNo: settings.manualGameVersionNo
          }));

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Tracking - Push productDetails event to dataLayer
          this.game().trackingProductDetails();

        },

        view: function (controller) {
          return (<NumberGamesSystem
            rootSettings={settings}
            rootProperty={property}
            gameInstance={controller.game}
            gameModel={EurojackpotGame}
            gameName={'eurojackpot'}
          />);
        }
      };

      return root;
    };

    // Public functions:
    return GameClient;

  });
