defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/EurojackpotReceipt',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/CouponOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotUtils',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotApi',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Component, Countdown, CouponOverlay, Overlay, EurojackpotDictionary, EurojackpotUtils, EurojackpotInfo, EurojackpotGame, AccountIntegration, Api, Ensighten) {

    // Component:
    Component('eurojackpot-receipt', [AccountIntegration, EurojackpotDictionary, EurojackpotInfo], function (m, route, settings) {

      // Variables:
      var d = EurojackpotDictionary.get;
      var id = EurojackpotUtils.getQueryParam('gameInstanceId');

      // Missing id:
      if (!id) {
        return;
      }

      var game = EurojackpotGame.get(id);

      // Missing game:
      if (!game) {
        console.error('EurojackpotReceipt: Game not found, id: ' + id);
        return;
      }

      // Components:
      var root = {
        controller: function () {

          // This controller:
          var controller = this;

          this.api = function () {
            return Api;
          }.bind(this);

          // Variables:
          this.game = m.prop(game);
          this.options = m.prop(settings || []);
          this.showSubscribeReminder = m.prop(false);
          this.alreadySubscribed = m.prop(false);
          this.subscribed = m.prop(false);
          this.loadingReminderAlert = m.prop(null);

          this.couponOverlay = m.prop(new Overlay(CouponOverlay('eurojackpot-coupon-overlay', this.game().couponId(), {
            printButton: d('EurojackpotReceiptStep/CouponOverlay/printButton'),
            closeButton: d('EurojackpotReceiptStep/CouponOverlay/closeButton')
          })));

          // Functions:
          this.back = function () {
            window.history.go(-1);
          };

          this.toggleReminderAlert = function () {
            var success = function () {
              if (this.subscribed()) {
                this.loadingReminderAlert('success-remind-me');
                Ensighten.pushGaEvent('permission', 'checkBoxActivated', 'multiWeekReminder', null, false);
              } else {
                this.loadingReminderAlert('success-dont-remind-me');
                Ensighten.pushGaEvent('permission', 'checkBoxDeactivated', 'multiWeekReminder', null, false);
              }
              m.redraw();
            }.bind(this);

            var fail = function (err) {
              if (err.errorMessage === 'NUMBERGAMES.USER_UNAUTHORIZED') {
                this.loadingReminderAlert('error-user-unauthorized');
              } else {
                this.loadingReminderAlert('error');
              }
              m.redraw();
            }.bind(this);

            this.subscribed(!this.subscribed());
            this.loadingReminderAlert('loading');
            this.api().setSubscribeReminder(this.subscribed()).then(success, fail);
            m.redraw();
          }.bind(this);

          this.gotoCoupon = function () {
            this.couponOverlay().show();
            return false;
          }.bind(this);

          var playType = d('PlayTypes/Eurojackpot' + this.game().playType());
          var totalPrice = this.game().totalPrice();
          var rows = this.game().getRowsSummary().length;
          var draws = this.game().numberOfDraws();
          var joker = this.game().withJokerSaturday() || this.game().withJokerWednesday();
          var jokerStake = this.game().stakePerJoker() * this.game().getJokerCount();
          var jokerCount = this.game().getJokerCount();
          var firstName;
          var lastName;
          var userName;

          AccountIntegration.getUserData().then(function (userData) {
            firstName = userData.firstName;
            lastName = userData.lastName;
            userName = userData.userName;
          });

          this.heading = d('ReceiptStep/Heading', {
            rows: rows,
            playType: playType,
            totalPrice: totalPrice,
            draws: draws,
            joker: joker ? 'med Joker' : 'uden Joker',
            jokerStake: jokerStake,
            jokerCount: jokerCount,
            firstName: firstName,
            lastName: lastName,
            userName: userName
          });


          var draw = EurojackpotInfo.data().openDraw;
          var drawDate = draw ? EurojackpotUtils.parseISO8601(draw.closingTime).dateObject : null;
          this.countdownOptions = {
            countdownTime: drawDate ? drawDate.getTime() / 1000 : 0,
            dictionary: d,
            disabled: settings.isCountdownDisabled,
            noOpenDraw: false
          };


          // Update balance in UI
          AccountIntegration.triggerBalanceUpdate();

          if (this.game().status() == 'completed') {
            // Ecommerce tracking:
            this.game().trackPurchase();
          }

          // If user logs out - redirect to Eurojackpot frontpage
          window.DSAPI && DSAPI.ready(function () {
            DSAPI.Account.subscribeUserLoggedOut({
              callback: function () {
                // TODO: make this more dynamic
                location.href = controller.game().startUrl();

              }
            });
          });

          if (this.game().numberOfDraws() > 1) {
            var success = function (data) {
              this.showSubscribeReminder(data.showSubscribeReminder);
              var subscribed = data.alreadySubscribed;
              this.alreadySubscribed(subscribed);
              this.subscribed(subscribed);
              m.redraw();
            }.bind(this);

            var fail = function () {
              // we fail silently by not showing the option to subscribe if the feed fails
            };

            this.api().getSubscribeReminder().then(success, fail);
          }
        },

        view: function (controller) {
          return m('.eurojackpot-receipt', [
            m('.countdown-wrapper', [
              m('.eurojackpot-section', [
                m('.eurojackpot-content-wrapper', [
                  m('.receipt-confirmation', [
                    settings.iconUrl != '' ?
                      m('svg', { class: 'receipt-icon' }, [
                        m('use', {
                          class: 'receipt-icon',
                          href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotCommonIcons.svg#' + settings.iconUrl
                        })
                      ])
                      : '',
                    m('p.receipt-text', controller.heading),
                    m('p.receipt-text', [
                      m('a.go-to-coupon', { onclick: controller.gotoCoupon }, d('ReceiptStep/GotoCoupon'))
                    ]),

                    controller.showSubscribeReminder() ? [
                      m('.reminder-alert', {
                        class: controller.loadingReminderAlert() === 'loading' ? 'reminder-alert--loading' : ''
                      }, [
                        m('input', {
                          type: 'checkbox',
                          id: 'reminderCheckbox',
                          checked: (controller.subscribed() ? 'checked' : ''),
                          onchange: m.withAttr('checked', controller.toggleReminderAlert)
                        }),
                        m('label', {
                          for: 'reminderCheckbox'
                        }, [
                          m('.checkbox'),
                          controller.alreadySubscribed() ?
                            d('ReceiptStep/SubscribeReminder/KeepRemindMe') :
                            d('ReceiptStep/SubscribeReminder/RemindMe')
                        ]),
                        controller.loadingReminderAlert() === 'error-user-unauthorized' ? m('p', d('ReceiptStep/SubscribeReminder/ErrorUserUnauthorized')) : '',
                        controller.loadingReminderAlert() === 'error' ? m('p', d('ReceiptStep/SubscribeReminder/Error')) : '',
                        controller.loadingReminderAlert() === 'success-remind-me' ? m('p', d('ReceiptStep/SubscribeReminder/SuccessRemindMe')) : '',
                        controller.loadingReminderAlert() === 'success-dont-remind-me' ? m('p', d('ReceiptStep/SubscribeReminder/SuccessDontRemindMe')) : '',
                      ])
                    ] : '',

                  ]),
                  Countdown(controller.countdownOptions)
                ])
              ])
            ])
          ]);
        }
      };
      // Setup Routes:
      route('/', root);
    });
  });
