defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/NumbersPickerHeader',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  ],
  function (m, Ensighten, Svg) {
    const NumbersPickerHeader = {
      controller: function ({ controller }) {
        Object.assign(this, controller);

        this.autoGeneratingNumbers = m.prop(false);
        this.showHeader = m.prop(true);
        if (this.useCompactView) {
          this.showHeader = m.prop(false);
          if (this.gameInstance().playType() === 'System') {
            this.showHeader = m.prop(true);
          }
        }
      },
      view: function (controller, { labels = {} }) {
        const totalRows = controller.gameInstance().playType() === 'System' ? controller.gameInstance().getSystemRowAmount() : controller.gameInstance().rowsToGenerate();
        const currentRowData = controller.gameInstance().getRow(controller.currentRow());
        const selectedNumbersCount = currentRowData.numbers.length;
        const maxNumberOfNumbers = controller.gameInstance().numbersPerRowMax();
        const remainingNumbers = maxNumberOfNumbers - selectedNumbersCount;
        let selectedSpecialNumbersCount = 0;
        let maxNumberOfSpecialNumbers = 0;
        let remainingSpecialNumbers = 0;
        switch (controller.gameName) {
        case 'vikinglotto':
          selectedSpecialNumbersCount = currentRowData.vikingNumber ? 1 : 0;
          maxNumberOfSpecialNumbers = controller.gameInstance().vikingNumbersPerRowMax();
          remainingSpecialNumbers = maxNumberOfSpecialNumbers - selectedSpecialNumbersCount;
          break;
        case 'eurojackpot':
          selectedSpecialNumbersCount = currentRowData.starNumbers.length;
          maxNumberOfSpecialNumbers = controller.gameInstance().starNumbersPerRowMax();
          remainingSpecialNumbers = maxNumberOfSpecialNumbers - selectedSpecialNumbersCount;
          break;
        }

        const areAllNumbersSelected = remainingNumbers === 0 && remainingSpecialNumbers === 0;
        const isAutoGenerateDisabled = controller.autoGeneratingNumbers() || areAllNumbersSelected;
        const isClearDisabled = selectedNumbersCount === 0 && selectedSpecialNumbersCount === 0;

        return (
          <div className={`flex items-center pb-8 ${controller.useCompactView ? 'justify-center flex-col gap-24' : 'justify-between border-solid border-0 border-b border-white/50'}`}>
            {controller.showHeader() ? <div data-uitest-id='numbers-table-header' className={`${controller.useCompactView ? 'text-center' : ''}`}>
              <div data-uitest-id='numbers-table-header-heading' className={`text-18 text-white leading-8 text font-bold uppercase ${controller.useCompactView ? 'pb-6 mb-6 border-solid border-0 border-b border-white/50' : ''}`}>
                {controller.labelDictionary(areAllNumbersSelected ? labels.rowCompletedHeading : labels.heading, {
                  selectedNumbersCount: selectedNumbersCount,
                  maxNumberOfNumbers: maxNumberOfNumbers,
                  remainingNumbers: remainingNumbers,
                  maxNumberOfSpecialNumbers: maxNumberOfSpecialNumbers,
                  remainingSpecialNumbers: remainingSpecialNumbers
                })}
              </div>
              <div data-uitest-id='numbers-table-header-subheading' className='text-14 text-white font-bold uppercase opacity-70'>
                {controller.labelDictionary(labels.subheading, {
                  currentRow: controller.currentRow(),
                  totalRows: totalRows,
                })}
              </div>
            </div> : null}
            <div className={`flex gap-16 ${controller.useCompactView ? 'w-full flex justify-around' : ''}`}>
              <div
                data-uitest-id='auto-row-fill-button'
                className={`flex gap-6 flex-col items-center text-white text-center ${isAutoGenerateDisabled ? (controller.autoGeneratingNumbers() ? 'cursor-wait' : 'cursor-not-allowed opacity-50') : 'cursor-pointer'}`}
                onclick={() => {
                  if (isAutoGenerateDisabled) {
                    return;
                  }

                  controller.autoGeneratingNumbers(true);
                  controller.gameInstance().autogenerateNumbers(controller.currentRow()).then(() => {
                    controller.autoGeneratingNumbers(false);
                    controller.gameInstance().save();
                    m.redraw();

                    Ensighten.pushGaEvent('coupon', `${controller.gameName}/controller.gameInstance().playType().toLowerCase()/numbers`, 'autofill single row');
                  }).catch(() => {
                    controller.autoGeneratingNumbers(false);
                    m.redraw();
                  });
                }}
              >
                <Svg
                  src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#shuffle-icon'
                  height='24'
                  width='24'
                />
                <div class='text-14 font-bold uppercase'>{controller.labelDictionary(labels.autofill)}</div>
              </div>
              <div
                data-uitest-id='clear-row-button'
                className={`flex gap-6 flex-col items-center text-white transition-opacity text-center ${isClearDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                onclick={() => {
                  controller.gameInstance().resetRow(controller.currentRow());

                  Ensighten.pushGaEvent('coupon', `${controller.gameName}/controller.gameInstance().playType().toLowerCase()/numbers`, 'clear numbers');
                }}
              >
                <Svg
                  src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#trash-bin-icon'
                  height='24'
                  width='24'
                />
                <div className='text-14 font-bold uppercase'>{controller.labelDictionary(labels.clear)}</div>
              </div>
            </div>
          </div>
        );
      }
    };

    return NumbersPickerHeader;
  });