defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/NonMithril/EurojackpotTopSpotResponsiveFrame', [
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (JackpotInfo, Utils) {
  // look for a handlebar such as {eurojackpot} in the data placeholder,
  // replace it with corresponding feed, and move it to the element
  if (!window.sitecoreEnv) {
    var $elements = document.querySelectorAll('.js-eurojackpot-top-spot-responsive-frame-replace-values');

    if ($elements.length > 0) {
      Utils.logDeprecated('EurojackpotTopSpotResponsiveFrame.js - $elements found'); // Added by MIPE 2022-08-04
    }

    $elements.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $element.textContent = JackpotInfo.getDynamicFeed($element.dataset.replaceValues || ''); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    });
  }
});
