defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/FooterSection',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/UIHelpers'
  ],
  function (m, UIHelpers) {
    // View:
    var FooterSection = function (controller) {
      var navigateToStep = function (index) {
        return function () {
          if (index > controller.currentFlowStepIndex()) return;
          controller.goToFlowStep(index);
        };
      };

      let showCtaButton = true;
      let stepsCount = controller.flowStepsNames().length;
      let stepStartIndex = controller.currentFlowStepIndex() + 1;
      if (controller.preGameClientStep) {
        stepsCount += 1;
        stepStartIndex += 1;

        if (controller.preGameClientStep.active() &&
            controller.currentFlowStepIndex() === -1) {
          stepStartIndex -= 1;
          showCtaButton = Boolean(controller.preGameClientStepShowCtaButton?.());
        }
      }

      if (controller.currentFlowStep() === 'confirm') {
        if (controller.askForDeposit() && controller.useQuickDeposit) {
          showCtaButton = false;
        }
      }

      const isClientStepActive = () => {
        return !controller.preGameClientStep?.active();
      };

      const headerBottomDistanceToTheTop = () => {
        const $stepHeader = controller.$element.querySelector('.multi-client__flow-step-header') || controller.$element.querySelector('.js-multi-client__flow-step-header'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        return $stepHeader ? ($stepHeader.clientHeight + $stepHeader.offsetTop) : null;
      };

      const ctaSlot = () => {
        if (!showCtaButton) return null;
        const numbersPickerCtaSlot = controller.property('numbersPickerCtaSlot')?.({
          numbersPickerGoToNextFlowStep: controller.goToNextFlowStep,
          currentFlowStepIndex: controller.currentFlowStepIndex
        });
        if (numbersPickerCtaSlot) {
          return m('.multi-client-cta-btn.use-tailwind-styling-class-only-for-automatic-tests.mb-30', numbersPickerCtaSlot);
        }

        return m('.multi-client-cta-btn', {
          'data-uitest-id': 'go-to-next-step-button',
          class: controller.canGoToNextStep() ? '' : ' btn--disabled',
          onclick: controller.goToNextFlowStep
        }, controller.nextButtonLabel());
      };

      // Return:
      return m('.multi-client-footer', {
        config: UIHelpers.stickyComponent({
          $container: controller.$element,
          topLimit: headerBottomDistanceToTheTop,
          key: 'multi-client-footer'
        })
      }, [
        ctaSlot(),
        m('.multi-client__progress-bar', [
          m('.multi-client__progress-bar-active-bar', {
            style: 'width:' + (stepStartIndex / (stepsCount <= 2 ? stepsCount : (stepsCount - 1)) * 100) + '%;'
          }),
          controller.preGameClientStep ? m('.multi-client__progress-bar-dot.multi-client__progress-bar-dot--active', {
            onclick: () => {
              navigateToStep(-1)();
              controller.gameSelectorOptions.isVisible = false;
              controller.preGameClientStep.active(true);
              controller.isThemeOn?.(true);
            }
          }) : null,
          m('.multi-client__progress-bar-dot', {
            class: (isClientStepActive() || controller.currentFlowStepIndex() !== -1) ? 'multi-client__progress-bar-dot--active' : '',
            onclick: () => {
              if (controller.currentFlowStepIndex() === -1) return;
              navigateToStep(-1)();
            }
          }),
          controller.flowStepsNames().map(function (currentFlowStep, index) {
            if (currentFlowStep === 'receipt') return null;
            return m('.multi-client__progress-bar-dot', {
              class: index <= controller.currentFlowStepIndex() ? 'multi-client__progress-bar-dot--active' : '',
              onclick: navigateToStep(index)
            });
          })
        ])
      ]);
    };

    return FooterSection;
  });
