defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/MultiClient', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotApi',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotClassic/DeleteOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/MultiClientDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/MultiClient/MultiClient'
], function (Component, AccountIntegration, Api, EurojackpotDictionary, EurojackpotInfo, EurojackpotGame, DeleteOverlay, mcDictionary, MultiClient) {
  Component('eurojackpot-multi-client', [AccountIntegration, EurojackpotDictionary, EurojackpotInfo, EurojackpotGame], function (m, ignoreRoute, settings, property, element) {
    this.d = EurojackpotDictionary.get;
    this.settings = settings;
    this.property = property;
    this.element = element;
    this.gameType = 'Eurojackpot';
    this.infoFeed = m.prop(EurojackpotInfo);
    this.isFeedDown = m.prop(this.infoFeed().isFeedDown());
    this.getLightningDefaultRowCount = m.prop(this.infoFeed().getLightningDefaultRowCount());
    this.getLightningOptions = m.prop(this.infoFeed().getLightningOptions);
    this.deleteOverlayForClassicRow = DeleteOverlay;
    this.api = function () {
      return Api;
    }.bind(this);

    this.isCampaignEngineOn = this.settings.useCampaignEngine;
    this.campaign = m.prop(this.settings.campaign);
    if (this.isCampaignEngineOn) {
      this.campaign(null);
    }

    // TODO: for now we will manually set the campaign to `null` until we get more information on how this should be handled for EJP
    this.campaign(null);

    var setItem = mcDictionary.setItem.bind(null, this.d);

    var eurojackpotDictionaries = {
      closedGame: {
        noOpenDrawHeader: setItem('EurojackpotClosed/FrontpageLightning/NoOpenDrawHeader'),
        noOpenDrawText1: setItem('EurojackpotClosed/FrontpageLightning/NoOpenDrawText_1'),
        noOpenDrawText2: setItem('EurojackpotClosed/FrontpageLightning/NoOpenDrawText_2'),
        gameNotAvailableHeader: setItem('EurojackpotClosed/FrontpageLightning/GameNotAvailableHeader'),
        gameNotAvailableText1: setItem('EurojackpotClosed/FrontpageLightning/GameNotAvailableText_1'),
        gameNotAvailableText2: setItem('EurojackpotClosed/FrontpageLightning/GameNotAvailableText_2')
      },
      draws: {
        drawSublabelSingleWeekSingleTicket: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabelSingleWeek: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabelSingleTicket: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabel: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabelNoTickets: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabelNoTickets')
      },
      receipt: {
        subscribeReminder: {
          keepRemindMe: setItem('ReceiptStep/SubscribeReminder/KeepRemindMe'),
          remindMe: setItem('ReceiptStep/SubscribeReminder/RemindMe'),
          errorUserUnauthorized: setItem('ReceiptStep/SubscribeReminder/ErrorUserUnauthorized'),
          error: setItem('ReceiptStep/SubscribeReminder/Error'),
          successRemindMe: setItem('ReceiptStep/SubscribeReminder/SuccessRemindMe'),
          successDontRemindMe: setItem('ReceiptStep/SubscribeReminder/SuccessDontRemindMe')
        }
      }
    };

    if (this.isCampaignEngineOn) {
      eurojackpotDictionaries.game = {
        descriptionZero: setItem('MultiClient/GameStep/CampaignEngine/DescriptionZero'),
        descriptionSingle: setItem('MultiClient/GameStep/CampaignEngine/DescriptionSingle'),
        description: setItem('MultiClient/GameStep/CampaignEngine/Description')
      };
    }

    mcDictionary.setDictionary.bind(this.d)(eurojackpotDictionaries);

    // Component
    MultiClient(m, EurojackpotGame, this);
  });
});