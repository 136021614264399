defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/ConfirmBar',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotUtils'
  ],
  function (m, EurojackpotUtils) {

    // Template:
    var ConfirmBar = function (controller) {

      // Variables:
      var d = controller.d;
      var game = controller.game();

      // View:
      return m('div', { class: 'confirm-bar-wrapper' }, [
        m('div', { class: 'confirm-bar' }, [
          m('div', { class: 'confirm-bar-content' }, [
            m('div', { class: 'confirm-bar-back', onclick: controller.back }, [
              m('a', { class: 'back' }, [
                m('div', { class: 'total-price' }, d('ConfirmBar/BackButton'))
              ])
            ]),
            controller.confirm ? m('div', { class: 'confirm-bar-cta' + (game.status() === 'pending-confirmation' ? ' processing' : ''), onclick: controller.confirm }, [
              m('a', { class: 'confirm' }, [
                m('div', { class: 'purchase-button-loader-box' }, [
                  m('span', { class: 'loader-text' }, d('ConfirmBar/Processing')),
                  m('span', { class: 'loader-animation-box' }, [
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' })
                  ])
                ]),
                m('div', { class: 'total-price' }, d('ConfirmBar/SubmitButton', { totalPrice: EurojackpotUtils.formatNumber(game.totalPrice()).toUpperCase() }))
              ])
            ]) : null
          ])
        ])
      ]);

    };

    // Public functions:
    return ConfirmBar;

  });
