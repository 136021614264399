defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/Dictionary'
  ],
  function (Dictionary) {

    // Variables:
    var eurojackpotDictionary = new Dictionary('/NumberGames/Eurojackpot');

    // Public functions:
    return eurojackpotDictionary;

  });
