defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/GameClients/EurojackpotClassicClient', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/Classic',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
], function (Params, Event, NumberGamesClassic, EurojackpotGame, EurojackpotInfo) {

  // Client:
  var GameClient = function (m, settings, property) {

    // Component:
    var root = {
      controller: function () {
        this.settings = settings;
        this.property = property;

        // Variables:
        this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
        this.game = m.prop(EurojackpotGame.setupGame({
          gameInstanceId: this.gameInstanceId(),
          playType: 'Classic',
          verticalType: settings.verticalType,
          manualGameVersionNo: settings.manualGameVersionNo
        }));

        // Replace state:
        if (!this.gameInstanceId() || this.gameInstanceId() !== this.game().id()) {
          Params.set('gameInstanceId=' + this.game().id());
        }

        // Tracking - Push productDetails event to dataLayer
        this.game().trackingProductDetails();

        Event.subscribe('eurojackpot:classic:createNewRow', () => this.property('callToCreateNewRow', true));
        Event.subscribe('numbersPicker:newGameInstanceGenerated', ({ id }) => {
          if (this.gameInstanceId() !== id) {
            Params.set('gameInstanceId=' + id);
          }
        });
      },

      view: function (controller) {
        return (<NumberGamesClassic
          rootSettings={settings}
          rootProperty={property}
          gameInstance={controller.game}
          gameModel={EurojackpotGame}
          gameName={'eurojackpot'}
          rowsOptions={EurojackpotInfo.getClassicOptions()}
          defaultRowCount={EurojackpotInfo.getClassicDefaultRowCount()}
        />);
      }
    };

    return root;
  };

  // Public functions:
  return GameClient;

});
